<template>
  <div class="user">
    <div class="left">
      <img :src="$img + data.content.thumb" alt="" v-if="data.content.thumb" />
      <el-skeleton-item animated variant="image" style="width: 300px; height: 433px" v-else />
    </div>
    <div class="right">
      <div class="content">
        <div class="quote">
          <svg-icon name="quote-gray"/>
        </div>
        <span>
          <div v-html="data.content.content" v-if="data.content.content" />
          <el-skeleton :rows="10" v-else />
        </span>

        <p>{{ data.content.author_en }}</p>
      </div>
    </div>

    <div class="share" v-if="false">
      <div class="basics">
        <svg-icon name="share" />
      </div>
      <div class="children">
        <div class="wb" @click="handlerToWb">
          <svg-icon name="wb" />
        </div>
        <div class="wx">
          <div class="box">
            <svg-icon name="wx" />
          </div>

          <div class="qr">
            <img :src="data.content.wx_share_img" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { apiArticle } from '@/api/list'
  import { onMounted, reactive } from 'vue'
  import useParams from '@/hooks/getParams.js'
  // 获取url中的参数
  let params = useParams()
  let data = reactive({
    content: ''
  })
  const regex = /style="[^"]*"/g
  const getArticle = async () => {
    let result = await apiArticle({
      id: params.detailsId
    })
    result.data.content = result.data.content_en.replace(regex, '')
    data.content = result.data
  }

  onMounted(() => {
    getArticle()
  })
</script>

<style lang="scss" scoped>
@media screen and (min-width: $media) {
  .user {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      width: 2.2891rem;
      height: 3.3828rem;
      background: linear-gradient(314deg, #b19a86 0%, #e3d5c9 100%);

      .el-skeleton__item,.el-skeleton__image {
        width: 100%;
        height: 100%;
      }

      > img {
        width: 2.3438rem;
        height: 3.3828rem;
        transform: translate(.1953rem, .1875rem);
      }
    }

    .right {
      position: relative;
      flex: 1;
      margin-left: .2422rem;
      .quote {
        position: absolute;
        top: .1172rem;
        left: .6172rem;
        z-index: 0;
        .svg-box {
          width: 1.1484rem;
          height: 1.0938rem;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 1.2891rem;

        > span {
          margin-top: .4922rem;
          width: 5.6641rem;
          font-size: .1875rem !important;
          font-weight: 500;
          color: #5a5a5a;
          text-indent: 2em;
          line-height: .3203rem;
          position: relative;
          z-index: 1;
        }
        p {
          font-size: .25rem;
          font-weight: 500;
          line-height: .4219rem;
          color: #5a5a5a;
          margin-top: .3906rem;
        }
      }
    }

    .share {
      position: absolute;
      right: -1.2578125rem;
      top: 2rem;
      width: .3906rem;
      height: .3906rem;
      background-color: #A6291A;
      z-index: 999;
      cursor: pointer;

      .basics {
        width: 100%;
        height: 100%;
        padding: .0781rem;
      }

      .children {
        opacity: 0;
        visibility: hidden;
        margin-top: .0391rem;
        width: .3906rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: all 0.3s;

        >div {
          width: .3906rem;
          height: .3906rem;
          background-color: #F0845C;
          margin-bottom: .0391rem;

        }

        .wb {
          width: 100%;
          padding: .0781rem;
        }

        .wx {
          position: relative;

          .box {
            width: .3906rem;
            height: .3906rem;
            padding: .0781rem;
          }

          .qr {
            visibility: hidden;
            opacity: 0;
            width: 1.5625rem;
            height: 1.5625rem;
            position: absolute;
            left: -1.6406rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            border: 1px solid #000;
            padding: .0781rem;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &:hover {
            .qr {
              opacity: 1;
              visibility: visible;
            }
          }
        }

      }

      &:hover {
        .children {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
  @media screen and (max-width: $media) {
    .user {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      width: 2.2891rem;
      height: 3.3828rem;
      background: linear-gradient(314deg, #b19a86 0%, #e3d5c9 100%);

      .el-skeleton__item,.el-skeleton__image {
        width: 100%;
        height: 100%;
      }

      > img {
        width: 2.3438rem;
        height: 3.3828rem;
        transform: translate(.1953rem, .1875rem);
      }
    }

    .right {
      position: relative;
      flex: 1;
      margin-left: .2422rem;
      .quote {
        position: absolute;
        top: .1172rem;
        left: .6172rem;
        z-index: 0;
        .svg-box {
          width: 1.1484rem;
          height: 1.0938rem;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 1.2891rem;

        > span {
          margin-top: .4922rem;
          width: 5.6641rem;
          font-size: .1875rem !important;
          font-weight: 500;
          color: #5a5a5a;
          text-indent: 2em;
          line-height: .3203rem;
          position: relative;
          z-index: 1;
        }
        p {
          font-size: .25rem;
          font-weight: 500;
          line-height: .4219rem;
          color: #5a5a5a;
          margin-top: .3906rem;
        }
      }
    }

    .share {
      position: absolute;
      right: -1.2578125rem;
      top: 2rem;
      width: .3906rem;
      height: .3906rem;
      background-color: #A6291A;
      z-index: 999;
      cursor: pointer;
      display: none;

      .basics {
        width: 100%;
        height: 100%;
        padding: .0781rem;
      }

      .children {
        opacity: 0;
        visibility: hidden;
        margin-top: .0391rem;
        width: .3906rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        transition: all 0.3s;

        >div {
          width: .3906rem;
          height: .3906rem;
          background-color: #F0845C;
          margin-bottom: .0391rem;

        }

        .wb {
          width: 100%;
          padding: .0781rem;
        }

        .wx {
          position: relative;

          .box {
            width: .3906rem;
            height: .3906rem;
            padding: .0781rem;
          }

          .qr {
            visibility: hidden;
            opacity: 0;
            width: 1.5625rem;
            height: 1.5625rem;
            position: absolute;
            left: -1.6406rem;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s;
            border: 1px solid #000;
            padding: .0781rem;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &:hover {
            .qr {
              opacity: 1;
              visibility: visible;
            }
          }
        }

      }

      &:hover {
        .children {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
</style>
